.flash {
  animation: flashBackground 1s infinite;
  font-weight: bold;
}

@keyframes flashBackground {
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #c9ba64; /* Change to desired flash color */
  }
  100% {
    background-color: #fff;
  }
}
